.app__navbar {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 1rem 2rem;
  background: white;
  position: fixed;
  z-index: 20;
  transition: top 0.3s ease;
}
.app__navbar-lang {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.app__navbar-lang-selector {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.app__navbar-lang-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  list-style: none;
  padding: 0.5rem 1rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.app__navbar-lang-dropdown li {
  padding: 0.5rem 0;
  cursor: pointer;
}

.app__navbar-lang-dropdown li:hover {
  background-color: #f0f0f0;
}

.app__navbar-logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 160px;
    height: 30px;

    @media screen and (min-width: 2000px) {
      width: 180px;
      height: 40px;
    }
  }
}

.app__navbar-links {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  list-style: none;

  li {
    margin: 0 1rem;
    cursor: pointer;

    flex-direction: column;

    div {
      width: 5px;
      height: 5px;
      background: transparent;
      border-radius: 50%;

      margin-bottom: 5px;
    }

    a {
      color: var(--gray-color);
      text-decoration: none;
      flex-direction: column;

      text-transform: uppercase;
      font-weight: 500;

      transition: all 0.3s ease-in-out;

      &:hover {
        color: var(--secondary-color);
      }
    }

    &:hover {
      div {
        background: var(--secondary-color);
      }
    }
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
}

.app__navbar-menu {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-color);

  svg {
    width: 70%;
    height: 70%;
    color: var(--white-color);
  }

  div {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 5;

    padding: 1rem;

    width: 80%;
    height: 100vh;

    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    background: url("../../assets/bgWhite.png");
    background-color: var(--white-color);
    background-size: cover;
    background-repeat: repeat;

    /* top box shadow */
    box-shadow: 0px 0px 20px rgba(168, 168, 168, 0.15);

    svg {
      width: 35px;
      height: 35px;
      color: var(--secondary-color);
      margin: 0.5rem 1rem;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      height: 100%;
      width: 100%;

      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      li {
        margin: 1rem;

        a {
          color: var(--gray-color);
          text-decoration: none;
          font-size: 1rem;
          text-transform: uppercase;
          font-weight: 500;

          transition: all 0.3s ease-in-out;

          &:hover {
            color: var(--secondary-color);
          }
        }
      }
    }

    @media screen and (min-width: 900px) {
      display: none;
    }
  }
  /* ... existing styles ... */

  .app__navbar-lang {
    position: relative;
  }

  .app__navbar-lang-selector {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    transition: all 0.3s ease;
  }

  .app__navbar-lang-selector:hover {
    background-color: #f0f0f0;
  }

  .app__navbar-lang-selector span {
    margin: 0 5px;
  }

  .app__navbar-lang-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    min-width: 120px;
  }

  .app__navbar-lang-dropdown li {
    padding: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .app__navbar-lang-dropdown li:hover {
    background-color: #f0f0f0;
  }

  .app__navbar-lang-dropdown li svg {
    margin-right: 5px;
  }

  /* Responsive styles */
  @media screen and (max-width: 900px) {
    .app__navbar-links {
      display: none;
    }

    .app__navbar-menu {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .app__navbar-lang {
      position: relative;
      top: auto;
      right: auto;
      z-index: 3;
      margin-right: 1rem;
    }

    .app__navbar-lang-selector {
      padding: 3px 6px;
      font-size: 0.9rem;
    }

    .app__navbar-lang-selector span {
      display: none;
    }

    .app__navbar-lang-dropdown {
      right: -10px;
    }
  }

  @media screen and (max-width: 500px) {
    .app__navbar {
      padding: 0.75rem 1rem;
    }
    
    .app__navbar-lang {
      margin-right: 0.75rem;
    }
    
    .app__navbar-lang-selector {
      padding: 2px 4px;
    }
    
    .app__navbar-menu {
      width: 30px;
      height: 30px;
    }
  }
  @media screen and (min-width: 900px) {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .app__navbar {
    padding: 1rem;
  }

  .app__navbar > * {
    margin-right: 10px;
  }

  .app__navbar > *:last-child {
    margin-right: 0;
  }
}

.flag-icon {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin-right: 5px;
  vertical-align: middle;
}
@media screen and (max-width: 900px) {
  .app__navbar-lang {
    position: fixed;
    top: 1.5rem;
    right: 3rem;
    z-index: 3;
  }
}
