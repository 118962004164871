/* General Styles */
.team-section {
  padding: 3rem 1rem;
  background-color: #ffffff; /* Background color remains white */
  border-top: 2px solid #e0e0e0;
  border-bottom: 2px solid #e0e0e0;
}

.team-header {
  text-align: center;
  margin-bottom: 3rem;
}

.header-subtitle {
  font-size: 1.25rem;
  color: #888;
  margin-bottom: 0.5rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  text-transform: uppercase;
}

.header-title {
  font-size: 2.5rem;
  color: #333;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  position: relative;
}

.header-title::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  width: 60px;
  height: 3px;
  background: linear-gradient(90deg, #00bfae, #005b96);
  border-radius: 2px;
}

/* Team Member Styles */
.team-members {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.team-member-wrapper {
  display: flex;
  align-items: center;
  gap: 20px;
  background: #f4f4f9;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  padding: 20px;
  overflow: hidden;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  background: linear-gradient(145deg, #ffffff, #f4f4f9);
  border: 1px solid #e0e0e0;
}

.team-member-wrapper::before {
  content: "";
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: radial-gradient(circle at top left, rgba(0, 191, 165, 0.3), transparent);
  z-index: 0;
}

.team-member-wrapper:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  transform: translateY(-8px);
}

.member-image {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  z-index: 1;
  border: 3px solid #00bfae;
  transition: transform 0.3s ease;
}

.member-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  filter: grayscale(40%);
  transition: transform 0.3s ease, filter 0.3s ease;
}

.team-member-wrapper:hover .member-image img {
  transform: scale(1.1);
  filter: grayscale(0%);
}

.member-info {
  flex: 1;
  position: relative;
  z-index: 1;
}

.member-info h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 0.5rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}

.member-info .position {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 0.5rem;
  font-family: 'Roboto', sans-serif;
}

.member-info .expertise {
  font-size: 1.1rem;
  color: #444;
  margin-bottom: 1rem;
  font-family: 'Roboto', sans-serif;
}

.member-info .details {
  font-size: 0.875rem;
  color: #333;
  line-height: 1.6;
  text-align: justify;
  font-family: 'Roboto', sans-serif;
}

/* Media Queries for responsiveness */
@media screen and (min-width: 768px) {
  .team-member-wrapper {
    flex-direction: row;
  }
}

@media screen and (min-width: 1024px) {
  .team-member-wrapper {
    max-width: 800px;
  }
}
