/* Blog Component Styles */
.app__about {
  flex: 1;
  width: 100%;
  flex-direction: column;
}

.app__profiles {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.app__profile-item {
  width: 300px; /* Standard card width */
  display: flex;
  flex-direction: column;
  margin: 1.5rem;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: #fff;
  transition: box-shadow 0.3s ease;
}

.app__profile-item:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.app__profile-item .img {
  width: 100%;
  height: 180px; /* Fixed height for images */
}

.app__profile-item .img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-bottom: 2px solid #ddd;
}

.app__profile-item .content {
  padding: 1rem;
}

.app__profile-item .content .category {
  font-size: 0.875rem;
  color: #888;
}

.app__profile-item .content .title h2 {
  font-size: 1.25rem;
  margin: 0.5rem 0;
  color: #333;
}

.app__profile-item .content .title a.arrow {
  color: #00bfae;
  text-decoration: none;
  font-size: 1.125rem;
  margin-top: 0.5rem;
}

.blog-card {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  
  .blog-image {
    width: 100%;
    height: 200px;
    overflow: hidden;
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.3s ease;
    }
  }
  
  .blog-content {
    padding: 1rem;
    
    .blog-date {
      font-size: 0.9rem;
      color: #666;
      margin-bottom: 0.5rem;
      display: block;
    }
    
    .blog-title {
      font-size: 1.1rem;
      font-weight: 600;
      margin-bottom: 0.75rem;
      line-height: 1.4;
    }
    
    .blog-excerpt {
      font-size: 0.95rem;
      color: #444;
      line-height: 1.5;
    }
  }
  
  &:hover {
    .blog-image img {
      transform: scale(1.05);
    }
  }
}

.blog-not-found {
  text-align: center;
  padding: 2rem;
  
  h2 {
    margin-bottom: 1rem;
    color: #333;
  }
  
  p {
    color: #666;
  }
}

.back-button {
  margin: 1rem;
  padding: 0.5rem 1rem;
  background: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  
  a {
    text-decoration: none;
    color: #333;
  }
  
  &:hover {
    background: #f5f5f5;
  }
}
