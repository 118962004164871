/* Main container */
.showcase-container {
  width: 50%;
  height: 100%;
  top: 0;
  padding-top: 10%;
  margin-left: 0;
  position: absolute;
  right: 0;
  overflow: hidden;
  perspective: 1200px;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.showcase-container.visible {
  opacity: 1;
  transform: translateY(0);
}

.showcase-container.hidden {
  opacity: 0.3;
  transform: translateY(20px);
}

/* Static stars */
.stars-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  pointer-events: none;
}

.star {
  position: absolute;
  border-radius: 50%;
  z-index: -1;
}

/* 3D Orbit System */
.orbit-system {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 0.3s ease-out;
  transform: rotateX(10deg) rotateY(20deg);
  animation: gentle-float 8s infinite alternate ease-in-out;
  will-change: transform;
}

@keyframes gentle-float {
  0% {
    transform: rotateX(10deg) rotateY(20deg) translateY(0);
  }
  100% {
    transform: rotateX(12deg) rotateY(22deg) translateY(-10px);
  }
}

/* Center logo */
.center-logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120px;
  height: 120px;
  z-index: 10;
  will-change: transform;
}

.logo-container {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(145deg, #ffffff, #f0f0f0);
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  animation: pulse 3s infinite ease-in-out;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
}

.center-image {
  width: 80%;
  height: 80%;
  object-fit: contain;
  animation: spin-slow 20s linear infinite;
  filter: drop-shadow(0 0 5px rgba(0, 0, 255, 0.2));
}

@keyframes spin-slow {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes pulse {
  0% {
    box-shadow: 0 8px 32px rgba(31, 38, 135, 0.2);
    transform: scale(1);
  }
  50% {
    box-shadow: 0 12px 48px rgba(31, 38, 135, 0.3), 0 0 20px rgba(100, 100, 255, 0.4);
    transform: scale(1.05);
  }
  100% {
    box-shadow: 0 8px 32px rgba(31, 38, 135, 0.2);
    transform: scale(1);
  }
}

/* Orbits */
.orbit {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transform-style: preserve-3d;
  transform: translate(-50%, -50%);
  will-change: transform;
}

.orbit-path {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid rgba(100, 100, 255, 0.1);
  box-shadow: 0 0 20px rgba(100, 100, 255, 0.05);
  animation: glow 4s infinite alternate;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 20px rgba(100, 100, 255, 0.05);
    border: 1px solid rgba(100, 100, 255, 0.1);
  }
  100% {
    box-shadow: 0 0 30px rgba(100, 100, 255, 0.2);
    border: 1px solid rgba(100, 100, 255, 0.3);
  }
}

.orbit-1 {
  width: 300px;
  height: 300px;
  animation: rotate-orbit 25s linear infinite;
}

.orbit-2 {
  width: 450px;
  height: 450px;
  animation: rotate-orbit-reverse 35s linear infinite;
}

.orbit-3 {
  width: 600px;
  height: 600px;
  animation: rotate-orbit 45s linear infinite;
}

@keyframes rotate-orbit {
  from {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotate-orbit-reverse {
  from {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  to {
    transform: translate(-50%, -50%) rotateZ(-360deg);
  }
}

/* Satellites */
.satellite {
  position: absolute;
  width: 80px;
  height: 80px;
  transform-style: preserve-3d;
  transform-origin: center;
  animation: satellite-pulse 4s infinite alternate ease-in-out;
  will-change: transform;
}

@keyframes satellite-pulse {
  0% {
    transform: translateZ(0);
  }
  100% {
    transform: translateZ(10px);
  }
}

.satellite-content {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 8px 32px rgba(31, 38, 135, 0.2);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: all 0.3s ease;
}

.satellite-content:hover {
  transform: scale(1.1);
  box-shadow: 0 12px 48px rgba(31, 38, 135, 0.3), 0 0 30px rgba(100, 100, 255, 0.4);
}

.satellite-content img {
  width: 70%;
  height: 70%;
  object-fit: contain;
  animation: spin 25s linear infinite;
  filter: drop-shadow(0 0 2px rgba(0, 0, 0, 0.2));
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* Satellite positions */
.satellite-1 {
  top: 0;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  animation-delay: 0.2s;
}

.satellite-2 {
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(50%);
  animation-delay: 0.4s;
}

.satellite-3 {
  top: 50%;
  left: 0;
  transform: translateX(-50%) translateY(-50%);
  animation-delay: 0.6s;
}

.satellite-4 {
  top: 50%;
  right: 0;
  transform: translateX(50%) translateY(-50%);
  animation-delay: 0.8s;
}

.satellite-5 {
  top: 30%;
  right: 0;
  transform: translateX(50%) translateY(-50%);
  animation-delay: 1s;
}

/* Floating particles */
.particles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.particle {
  position: absolute;
  width: var(--size);
  height: var(--size);
  background: linear-gradient(145deg, hsl(var(--hue), 80%, 60%), hsl(var(--hue), 80%, 40%));
  border-radius: 50%;
  opacity: var(--opacity);
  animation: float var(--duration) ease-in-out var(--delay) infinite;
  top: var(--y-start);
  left: var(--x-start);
  filter: blur(1px);
  transform: translateZ(calc(var(--depth) * -50px));
  will-change: transform, opacity;
}

@keyframes float {
  0% {
    transform: translate(0, 0) scale(1) translateZ(calc(var(--depth) * -50px));
    opacity: var(--opacity);
  }
  50% {
    transform: translate(
      calc((var(--x-end) - var(--x-start)) / 2),
      calc((var(--y-end) - var(--y-start)) / 2)
    ) scale(1.2) translateZ(calc(var(--depth) * -40px));
    opacity: calc(var(--opacity) * 1.5);
    box-shadow: 0 0 10px rgba(52, 152, 219, 0.5);
  }
  100% {
    transform: translate(
      calc(var(--x-end) - var(--x-start)),
      calc(var(--y-end) - var(--y-start))
    ) scale(1) translateZ(calc(var(--depth) * -50px));
    opacity: var(--opacity);
  }
}

/* Responsive styles */
@media screen and (max-width: 1200px) {
  .orbit-1 {
    width: 250px;
    height: 250px;
  }
  
  .orbit-2 {
    width: 380px;
    height: 380px;
  }
  
  .orbit-3 {
    width: 500px;
    height: 500px;
  }
  
  .satellite {
    width: 70px;
    height: 70px;
  }
}

@media screen and (max-width: 768px) {
  .showcase-container {
    width: 100%;
    height: 50vh;
    position: relative;
    padding-top: 0;
    margin-top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: visible;
    right: auto;
    min-height: 350px;
  }
  
  .orbit-system {
    transform: scale(1.0) rotateX(10deg) rotateY(20deg);
    height: 100%;
    animation: gentle-float-mobile 8s infinite alternate ease-in-out;
  }
  
  @keyframes gentle-float-mobile {
    0% {
      transform: scale(1.0) rotateX(10deg) rotateY(20deg) translateY(0);
    }
    100% {
      transform: scale(1.0) rotateX(12deg) rotateY(22deg) translateY(-10px);
    }
  }
  
  .center-logo {
    width: 100px;
    height: 100px;
  }
  
  .orbit-1 {
    width: 220px;
    height: 220px;
  }
  
  .orbit-2 {
    width: 320px;
    height: 320px;
  }
  
  .orbit-3 {
    width: 420px;
    height: 420px;
  }
  
  .satellite {
    width: 65px;
    height: 65px;
  }
}

/* Additional mobile fixes */
@media screen and (max-width: 480px) {
  .showcase-container {
    height: 50vh;
    margin-top: 0;
    min-height: 300px;
  }
  
  .orbit-system {
    transform: scale(0.8) rotateX(10deg) rotateY(20deg);
    animation: gentle-float-small 8s infinite alternate ease-in-out;
  }
  
  @keyframes gentle-float-small {
    0% {
      transform: scale(0.8) rotateX(10deg) rotateY(20deg) translateY(0);
    }
    100% {
      transform: scale(0.8) rotateX(12deg) rotateY(22deg) translateY(-10px);
    }
  }
  
  .center-logo {
    width: 90px;
    height: 90px;
  }
  
  .orbit-1 {
    width: 200px;
    height: 200px;
  }
  
  .orbit-2 {
    width: 280px;
    height: 280px;
  }
  
  .orbit-3 {
    width: 360px;
    height: 360px;
  }
  
  .satellite {
    width: 55px;
    height: 55px;
  }
}

/* Fix for very small screens */
@media screen and (max-width: 360px) {
  .showcase-container {
    height: 45vh;
    min-height: 250px;
  }
  
  .orbit-system {
    transform: scale(0.7) rotateX(10deg) rotateY(20deg);
  }
  
  @keyframes gentle-float-small {
    0% {
      transform: scale(0.7) rotateX(10deg) rotateY(20deg) translateY(0);
    }
    100% {
      transform: scale(0.7) rotateX(12deg) rotateY(22deg) translateY(-10px);
    }
  }
  
  .center-logo {
    width: 80px;
    height: 80px;
  }
  
  .orbit-1 {
    width: 180px;
    height: 180px;
  }
  
  .orbit-2 {
    width: 260px;
    height: 260px;
  }
  
  .orbit-3 {
    width: 340px;
    height: 340px;
  }
  
  .satellite {
    width: 50px;
    height: 50px;
  }
}
