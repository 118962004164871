.footer-container {
  width: 100%;
  padding: 4rem 2rem;
  background: var(--white-color);
  
  .head-text {
    font-size: 2.5rem;
    font-weight: 800;
    text-align: center;
    color: var(--black-color);
    text-transform: capitalize;
    margin-bottom: 3rem;
    
    @media screen and (max-width: 768px) {
      font-size: 2rem;
    }
  }
}

.footer-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  
  h3 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    color: var(--secondary-color);
  }
}

.contact-info {
  .app__footer-cards {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .app__footer-card {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0 8px 32px rgba(31, 38, 135, 0.1);
    backdrop-filter: blur(4px);
    border: 1px solid rgba(255, 255, 255, 0.18);
    transition: all 0.3s ease;
    
    img {
      width: 40px;
      height: 40px;
      margin-right: 0.7rem;
    }
    
    a {
      color: var(--gray-color);
      text-decoration: none;
      font-weight: 500;
      
      &:hover {
        color: var(--secondary-color);
      }
    }
    
    &:hover {
      box-shadow: 0 8px 32px rgba(31, 38, 135, 0.2);
      transform: translateY(-5px);
    }
  }
}

.social-connect {
  .social-links {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
  }
  
  .social-link {
    display: flex;
    align-items: center;
    padding: 1rem;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0 8px 32px rgba(31, 38, 135, 0.1);
    text-decoration: none;
    color: var(--gray-color);
    transition: all 0.3s ease;
    
    img {
      width: 24px;
      height: 24px;
      margin-right: 0.7rem;
    }
    
    span {
      font-weight: 500;
    }
    
    &:hover {
      box-shadow: 0 8px 32px rgba(31, 38, 135, 0.2);
      transform: translateY(-5px);
    }
    
    &.whatsapp:hover {
      background: #25D366;
      color: white;
    }
    
    &.facebook:hover {
      background: #1877F2;
      color: white;
    }
    
    &.linkedin:hover {
      background: #0A66C2;
      color: white;
    }
  }
}

.copyright {
  margin-top: 3rem;
  text-align: center;
  color: var(--gray-color);
  font-size: 0.9rem;
  
  p {
    opacity: 0.8;
  }
}

@media screen and (max-width: 768px) {
  .footer-container {
    padding: 2rem 1rem;
  }
  
  .footer-content {
    grid-template-columns: 1fr;
  }
  
  .social-links {
    grid-template-columns: 1fr !important;
  }
}
