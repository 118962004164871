/* Base styles */
.experience {
  padding: 20px;
  margin: 4rem auto;
  max-width: 850px;
  /* Remove fixed height */
  /* height: 600px; */
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  backdrop-filter: blur(10px);
  transition: background 0.3s, color 0.3s;
  /* Adjust overflow if needed */
  overflow: visible;
}

.title h2 {
  font-size: 2rem;
  font-weight: 700;
  color: #2c3e50;
  position: relative;
  padding-bottom: 1rem;
}

.title h2::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 50px;
  height: 3px;
  background: #3498db;
  transform: translateX(-50%);
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;
  /* Remove fixed height */
  /* height: 500px; */
}

.exp-slider {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.exp-slider-item {
  padding: 10px;
  cursor: pointer;
  width: 100%; /* Adjusted for responsiveness */
  transition: background 0.3s ease, color 0.3s ease;
  border-radius: 8px;
  position: relative;
  z-index: 1;
  margin-bottom: 5px;
}

.exp-slider-item:hover,
.exp-slider-item-selected {
  background-color: #f0f0f0;
  z-index: 2;
}

.exp-slider-item:hover span,
.exp-slider-item-selected span {
  color: #3498db;
}

.underline {
  position: absolute;
  width: 4px;
  background-color: #3498db;
  transition: top 0.3s, height 0.3s;
}

.exp-details {
  margin-top: 20px;
  margin: 0;
  padding: 0;
  width: 100%; /* Changed to be responsive */
  /* Remove fixed height */
  /* height: 500px; */
  transition: background 0.3s, color 0.3s;
}

.exp-details-position {
  margin-top: 10px;
  width: 100%; /* Changed to be responsive */
  background: #fff;
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  position: relative;
}

.exp-details-position h3 {
  font-size: 1.5rem;
  color: #2c3e50;
}

.exp-details-position-company {
  color: #3498db;
}

.exp-details-range {
  font-size: 1rem;
  font-weight: 500;
  color: #2c3e50;
  background-color: #ecf0f1;
  padding: 0.3rem 0.6rem;
  border-radius: 5px;
  display: inline-block;
  line-height: 1.5;
}

.exp-details-list {
  list-style: none;
  padding: 0;
  margin-top: 1rem;
}

.exp-details-list-item {
  margin-bottom: 10px;
  color: #2c3e50;
  position: relative;
  padding-left: 1.5rem;
}

.exp-details-list-item::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  background: #3498db;
  transform: scaleX(0);
  transform-origin: bottom right;
  transition: transform 0.4s ease;
}

.exp-details-list-item:hover::before {
  transform: scaleX(1);
  transform-origin: bottom left;
}

/* Media queries for responsiveness */
@media (min-width: 768px) {
  .container {
    flex-direction: row;
    justify-content: space-between;
    /* Remove fixed height */
    /* height: 500px; */
  }

  .exp-slider {
    flex-direction: column;
    width: 30%;
  }

  .exp-details {
    width: 65%;
  }
}

@media (min-width: 1024px) {
  .title h2 {
    font-size: 2.5rem;
  }

  .exp-details-position {
    width: 500px; /* Apply fixed width on larger screens */
  }

  .exp-details-position h3 {
    font-size: 2rem;
  }

  .exp-details-range {
    font-size: 1.2rem;
  }
}
