.blog-details-container {
  width: 100%;
  min-height: 100vh;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  padding: 2rem 1rem;
}

.blog-details-wrapper {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.blog-header-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.back-button {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  color: #10a37f;
  transition: color 0.3s ease;

  &:hover {
    color: #0e8a64;
  }

  a {
    text-decoration: none;
    color: inherit;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  span {
    font-size: 1.2rem;
  }
}

.blog-date {
  color: #666;
  font-size: 0.9rem;
}

.blog-content {
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease forwards;
}

.blog-header {
  text-align: center;
  margin-bottom: 2rem;
}

.blog-title {
  font-size: 2.5rem;
  font-weight: 700;
  color: #1a1a1a;
  line-height: 1.3;
  margin-bottom: 1rem;

  @media screen and (max-width: 768px) {
    font-size: 2rem;
  }
}

.blog-hero-image {
  width: 100%;
  margin-bottom: 2rem;
  border-radius: 8px;
  overflow: hidden;
  
  img {
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: transform 0.3s ease;
  }

  &:hover img {
    transform: scale(1.02);
  }
}

.blog-body {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #333;
}

.blog-section {
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.section-title {
  font-size: 1.8rem;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 1rem;
  line-height: 1.4;

  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
  }
}

.blog-text {
  margin-bottom: 1.5rem;
  
  &:last-child {
    margin-bottom: 0;
  }
}

.blog-not-found {
  text-align: center;
  padding: 4rem 2rem;
  
  h2 {
    font-size: 2rem;
    color: #1a1a1a;
    margin-bottom: 1rem;
  }
  
  p {
    color: #666;
    font-size: 1.1rem;
  }
}

// Responsive adjustments
@media screen and (max-width: 768px) {
  .blog-details-wrapper {
    padding: 0 1rem;
  }

  .blog-title {
    font-size: 2rem;
  }

  .blog-body {
    font-size: 1rem;
  }
}

// Animations
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

// Ensure images are responsive and maintain aspect ratio
img {
  max-width: 100%;
  height: auto;
  display: block;
}

.modal-blog {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.modal-img {
  width: 100%;
  text-align: center;
}

.modal-img img {
  max-width: 100%;
  height: auto;
}

.modal-text {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.modal-text span {
  display: block;
  margin-bottom: 20px;
}

.modal-text h1 {
  margin-bottom: 10px;
}

.modal-text p {
  margin-bottom: 20px;
}

.contact {
  width: 100%;
}

.blog_contact {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.blog_contact .left,
.blog_contact .right {
  flex: 1;
  min-width: 300px;
}

.blog_contact input,
.blog_contact textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  box-sizing: border-box;
}

.blog_contact .btn_shadow {
  display: block;
  margin-top: 10px;
}
