.project-banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 19; /* Just below navbar z-index of 20 */
  background: rgba(255, 255, 255, 0.98);
  color: #374151;
  padding: 0.6rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.875rem;
  border-bottom: 1px solid #e5e7eb;
  font-weight: var(--font-weight-normal);
  transition: transform 0.3s ease, opacity 0.2s ease;
  will-change: transform;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);

  .mobile-only {
    display: none;
  }

  .banner-content {
    display: flex;
    align-items: center;
    gap: 1rem;
    max-width: 1200px;
    margin: 0 auto;

    span {
      color: #374151;
    }

    a {
      color: #2563eb;
      text-decoration: none;
      font-weight: var(--font-weight-medium);
      transition: color 0.2s ease;
      display: inline-flex;
      align-items: center;
      gap: 0.25rem;

      &:hover {
        color: #1d4ed8;
        text-decoration: underline;
      }
    }
  }

  .close-button {
    position: absolute;
    right: 1rem;
    background: none;
    border: none;
    color: #6b7280;
    font-size: 1.25rem;
    cursor: pointer;
    padding: 0.25rem 0.5rem;
    line-height: 1;
    opacity: 0.7;
    transition: all 0.2s ease;
    border-radius: 4px;

    &:hover {
      opacity: 1;
      background: #f3f4f6;
    }
  }

  @media screen and (max-width: 768px) {
    padding: 0.6rem 1rem;
    
    .banner-content {
      flex-direction: row;
      gap: 0.5rem;
      text-align: center;
      padding-right: 2rem;
    }

    .desktop-only {
      display: none;
    }

    .mobile-only {
      display: inline;
    }
  }

  @media screen and (max-width: 500px) {
    padding: 0.5rem 0.75rem;
    font-size: 0.8rem;
    
    .banner-content {
      justify-content: center;
    }
    
    .close-button {
      right: 0.5rem;
      font-size: 1rem;
    }
  }
} 